@import '@hh.ru/magritte-ui/breakpoints';

.benefits-list {
    display: flex;
    flex-direction: column;
    gap: 56px;
}

.benefits-list-item {
    display: flex;
    flex-direction: row;

    .screen-gt-xs({
        align-items: center;
    });
}

.benefits-list-item-image {
    width: 44px;
    height: 44px;
    margin-right: 32px;
    flex-shrink: 0;

    background: center top / contain no-repeat url('circle.svg');
}
