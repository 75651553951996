@import '@hh.ru/magritte-ui/breakpoints';

.publish-vacancy {
    text-align: center;
    margin: 0 16px;

    .screen-gt-xs({
        max-width: 500px;
        margin: 0;
    });

    .screen-gt-l({
        max-width: 768px;
        margin: 0;
    });
}
