@import '@hh.ru/magritte-ui/breakpoints';

.search-steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 18px;

    .screen-gt-xs({
        padding: 0;
    });
}

.employers-online-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .screen-gt-xs({
        flex-direction: row;
        align-items: baseline;
        width: 100%;
        gap: 0;
        padding: 0 58px;
    });
}

.search-step-item {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
    width: 100%;

    .screen-gt-xs({
        flex-direction: column;
        padding: 0 24px;
        gap: 0;
    });
}

.search-step-text-item {
    .screen-gt-xs({
        text-align: center;
        max-width: 252px;
    });

    .screen-gt-s({
        text-align: center;
        max-width: 290px;
    });
}

.search-step-number {
    background-size: contain;
    width: 74px;
    height: 64px;

    .screen-gt-xs({
        width: 100%;
        height: 72px;
        margin-bottom: 28px;
    });

    .screen-gt-s({
        width: 100%;
        height: 72px;
    });
}

.search-step-item-arrow {
    position: absolute;
    top: 0;
    right: -41px;
    background: url('./assets/arrow.svg') 50% 50% no-repeat;
    height: 16px;
    width: 82px;
}

.search-step-item-1 {
    background: url('./assets/1.svg') 50% 50% no-repeat;
    .search-step-number();
}

.search-step-item-2 {
    background: url('./assets/2.svg') 50% 50% no-repeat;
    .search-step-number();
}

.search-step-item-3 {
    background: url('./assets/3.svg') 50% 50% no-repeat;
    .search-step-number();
}
