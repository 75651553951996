@import '@hh.ru/magritte-ui/breakpoints';

.search-group {
    display: flex;
    gap: 24px;
    position: relative;
    width: 100%;

    .screen-gt-s({
        width: none;
    });
}

.search-submit {
    display: none;

    .screen-gt-s({
        display: flex;
    });
}

.frequently-search-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    .screen-gt-xs({
        flex-direction: row;
        align-items: baseline;
    });
}

.frequently-search-title {
    white-space: nowrap;
}

.frequently-search-items {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;

    .screen-gt-xs({
        justify-content: left
    });
}

.resume-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 18px;

    .screen-gt-xs({
        padding: 0;
    });
}

.search-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
