@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.header {
    font-family: @magritte-font-families-heading;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
    /* stylelint-disable-next-line unit-allowed-list */
    letter-spacing: -0.0125em;

    .screen-gt-xs({
        font-size: 52px;
        line-height: 100%;
    });

    @media (min-width: 1440px) and (min-height: 900px) {
        body:not(:global(.magritte-old-layout)) & {
            font-size: 72px;
            line-height: 72px;
        }
    }
}

:global(.magritte-with-inter) .header {
    font-family: inherit;
}
