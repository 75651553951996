@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.price-card-image {
    width: 100%;
    height: 143px;
    border-radius: 12px;
    overflow: hidden;

    .screen-gt-xs({
        height: 160px;
    });

    .screen-gt-s({
        height: 175px;
    });
}

.image-vacancies:not(.alt-image) {
    background-image: url('vacancies-sm.svg');

    .screen-gt-xs({
        background-image: url('vacancies-md.svg');
    });

    .screen-gt-s({
        background-image: url('vacancies-lg.svg');
    });
}

.image-candidates:not(.alt-image) {
    background-image: url('candidates-sm.svg');

    .screen-gt-xs({
        background-image: url('candidates-md.svg');
    });

    .screen-gt-s({
        background-image: url('candidates-lg.svg');
    });
}

.alt-image {
    background-size: contain;

    &.image-vacancies {
        background-image: url('vacancies-alt.svg');
    }

    &.image-candidates {
        background-image: url('candidates-alt.svg');
    }
}
