@import '@hh.ru/magritte-ui/breakpoints';

.container {
    text-align: center;
}

.cards-container {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    display: flex;
    align-items: stretch;
    padding-bottom: 32px;
    column-gap: 12px;
    scroll-padding: 16px;
    max-width: 100vw;

    transition: all 0.5s;
    opacity: 0;
    transform: translateY(50px);

    .screen-gt-s({
        justify-content: center;
        column-gap: 24px;
    });
}

.cards-container-visible {
    opacity: 1;
    transform: translateY(0);
}

.cards-container::before {
    content: '';
    padding-left: 16px;
}

.cards-container::after {
    content: '';
    padding-right: 16px;
}

.card-option-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
