@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.container {
    position: relative;
    scroll-snap-align: start;
}

.card-content-container {
    width: 276px;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;

    .screen-gt-xs({
        width: 308px;
    });

    .screen-gt-s({
        width: 470px;
    });
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;

    .screen-gt-s({
        flex-direction: row;
    });
}

.card-list-container {
    padding: 0 12px;
    flex: 1;
}

.top-tag {
    position: absolute;
    padding: 0 3px;
    background: @magritte-color-background-primary;
    visibility: hidden;
}
