@import '@hh.ru/magritte-ui/breakpoints';

.hero-section {
    height: 75vh;
    min-height: 300px;
    max-height: 450px;

    .screen-gt-xs({
        min-height: 450px;
        max-height: 600px;
    });

    .screen-gt-s({
        min-height: 520px;
        max-height: 700px;

        background-size: contain;
        background-image: url('bg.svg');
        background-position-x: 50%;
        background-position-y: 50%;
        background-repeat: no-repeat;
    });
}

.hero-section-wide {
    .screen-gt-s({
        max-height: 517px;
        background-image: url('bg-wide.svg');
    });
}
