@import '@hh.ru/magritte-ui/tokens';

.chips-item {
    padding: 8px 16px;
    border-radius: 20px;
    border: 0;
    background-color: @magritte-color-base-white;
    transition: background 0.1s linear;

    &:hover {
        background-color: @magritte-color-background-tertiary;
    }
}

.chips-item-text {
    display: inline-block;
    white-space: nowrap;

    &::first-letter {
        text-transform: capitalize;
    }
}
