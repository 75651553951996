@import '@hh.ru/magritte-ui/tokens';

.button {
    border-width: 0;
    display: inline-block;
    background: none;
    padding: 0;

    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    appearance: none;

    cursor: pointer;

    &::-moz-focus-inner {
        border: 0;
    }

    &:focus {
        outline: none;
        text-decoration: none;
    }

    [disabled] &,
    &:disabled,
    &[aria-disabled='true'] {
        pointer-events: none;
        cursor: not-allowed;
    }

    &:not(:disabled):active,
    &:not([aria-disabled='true']):active {
        .button-view {
            transform: scale(@magritte-semantic-scale-pressed);
            transition-duration: @magritte-semantic-animation-ease-in-out-100-duration;
            transition-timing-function: @magritte-semantic-animation-ease-in-out-100-timing-function;
        }
    }

    &:global(.focus-visible) .button-view {
        /* stylelint-disable-next-line declaration-property-value-disallowed-list */
        outline: 4px solid #ffe0007a;
    }
}

.button-view {
    display: inline-block;
    width: 100%;
    position: relative;
    line-height: 0;
    border-radius: @magritte-core-border-radius-3-x;
    box-sizing: border-box;
    padding: @magritte-static-space-400 @magritte-static-space-500;

    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background-color: #ffe000;
    transition-property: transform;
    transition-duration: @magritte-semantic-animation-ease-in-out-200-duration;
    transition-timing-function: @magritte-semantic-animation-ease-in-out-200-timing-function;
}

.button-content {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: @magritte-static-space-200;
    width: 100%;
    min-height: 24px;
    min-width: 24px;
}

@media (prefers-reduced-motion) {
    .button,
    .button:not(:disabled):active {
        .button-view {
            transition-duration: 0ms;
        }
    }
}

.button-label {
    .magritte-typography-subtitle-1-semibold();

    min-height: @magritte-typography-subtitle-1-semibold-line-height;
    color: @magritte-color-base-black;
    white-space: nowrap;
    text-align: center;
}

.stretched {
    width: 100%;

    .button-label {
        flex-grow: 1;
        min-width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
