@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.container {
    width: 74px;
    height: 74px;
    position: relative;
    margin-right: 16px;
    flex-shrink: 0;

    .screen-gt-xs({
        width: 120px;
        height: 120px;
        margin-right: 32px;
    });
}

.content-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
}

.content {
    font-family: @magritte-font-families-heading;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    text-align: center;

    .screen-gt-xs({
        font-size: 22px;
    });
}

:global(.magritte-with-inter) .content {
    font-family: inherit;
}

.circle-background {
    stroke: @magritte-color-background-positive-secondary;
}

.circle-percent {
    z-index: 1;
    stroke: @magritte-color-background-positive;
    stroke-linecap: round;
}
