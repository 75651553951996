.centered {
    display: flex;
    justify-content: center;
    width: 100%;
}

.vertical {
    align-items: center;
    height: 100%;
}
